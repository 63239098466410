<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Contratos - Campos adicionales'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        @loadModalData="loadModalData"
                        @setFilters="setFilters"
                        :showAdvancedFilters="true"
                        :perPage="[10,25,50,100]"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar" >
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">  
                                <v-autocomplete
                                    outlined
                                    label="Clientes"
                                    :items="clientes"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="cliente_value"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{item.cliente.nombre}}</div></td>
                                <td><div>{{item.campo_nombre}}</div></td>
                                <td><div>{{item.descripcion}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonHover" icon fab dark @click="abrirModal('update', item)" small>
                                            <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                            <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Eliminar</span>
                                    </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
        </v-container>

        <template>
            <v-dialog v-model="dialog" persistent max-width="600px">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                </template>
                <v-card>
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                        <v-card-title class="justify-center card_titulo">
                            <div class="headerModal">
                                <img :src="imagen" class="imgModal" />
                                <h2 class="titleModal">{{ tituloModal }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="card_texto">
                            <div id="padre" class="d-flex justify-center my-10" v-if="isLoading">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-if="!isLoading" grid-list-md id="contenedor">
                                <v-divider class="divider"></v-divider>
                                <v-row>
                                    <template v-if="rol == 'root'">
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Cliente"
                                                    :items="clientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="cliente_value"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors"
                                                    :readonly="accion == 'add'?false:true"
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                    </template>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0 my-0">
                                        <ValidationProvider v-slot="{ errors }" name="Campo" rules="required">
                                            <v-text-field 
                                                outlined
                                                label="Campo" 
                                                class="pa-0 ma-0" 
                                                v-model="campo.campo_nombre" 
                                                :error-messages="errors" 
                                                required
                                                :readonly="accion == 'add'?false:true"
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0 my-0">
                                        <ValidationProvider v-slot="{ errors }" name="Descripción" rules="required">
                                            <v-text-field 
                                                outlined
                                                label="Descripción" 
                                                class="pa-0 ma-0" 
                                                v-model="campo.descripcion" 
                                                :error-messages="errors" 
                                                required
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModal()" 
                                :disabled="isSaving"
                            >
                                Cancelar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                @click="guardar()" 
                                :disabled="invalid" 
                                :loading="isSaving"
                            >
                                Guardar
                            </v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </template>
    </div>
</template>

<script>
import apiContratos from '@/api/contratos';
import apiClientes from "@/api/clientes";
import Datatable from '@/components/datatable/Datatable.vue';
import Notify from '@/plugins/notify';

export default {
    components: {
        'data-table': Datatable,
    },
    data() {
        return {
            url         : "contratos-adicionales/campos/find",
            columns     : [
                {
                    label: 'Cliente',
                    name: 'cliente_id',
                    filterable: false
                },
                {
                    label: 'Campo',
                    name: 'Campo_2',
                    filterable: false
                },
                {
                    label: 'Descripción',
                    name: 'descripcion',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters     : {
                activo    :true,
                paginate  :true,
                order     :"asc"
            },
            campo        : {
                id              : null,
                cliente_id      : null,
                campo_nombre    : null,
                descripcion     : null,
            },
            datosLogin      : null,
            rol             : null,
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            isLoading       : false,
            isSaving        : false,
            imagen          : "",
            clientes        : [],
            cliente_value   : null,
        }
    },
    methods: {
        abrirModal(accion, data = null) {
            this.accion     = accion;
            this.isLoading  = true;

            this.getClientes();

            if(accion == "add") {
                this.tituloModal    = "Nuevo campo";
                this.imagen         = "/static/modal/catalogoSATcreate.svg";
            } else {
                this.tituloModal            = "Actualizar campo";
                this.imagen                 = "/static/modal/catalogoSATupdate.svg";
                this.campo.id             = data.id;
                this.campo.cliente_id     = data.cliente_id;
                this.campo.campo_nombre   = data.campo_nombre;
                this.campo.descripcion    = data.descripcion;
                this.cliente_value        = data.cliente_id;
            }

            this.isLoading  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
            this.cliente_value          = null;
            this.campo.id               = null;
            this.campo.cliente_id       = null;
            this.campo.campo_nombre     = null;
            this.campo.descripcion      = null;

            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },
        guardar() {
            this.$refs.form.validate().then(success => {
            this.isSaving = true;
            if(!success) {
                this.isSaving = false;
                return;
            }

            this.campo.cliente_id = this.cliente_value;

            if(this.accion === "add") {
                apiContratos.storeCampos(this.campo).then((response) => {
                    this.$refs.tabla.getData();
                    Notify.Success("Operación exitosa", "El campo se guardó satisfactoriamente.");
                    this.cerrarModal();
                    this.$nextTick(() => {
                        this.$refs.form.reset();
                    });
                })
                .catch(err => {
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });
            }
            else if(this.accion === "update") {
                apiContratos.updateCampos(this.campo).then((response) => {
                    this.$refs.tabla.getData();
                    Notify.Success("Operación exitosa", "El campo se actualizó satisfactoriamente.");
                    this.cerrarModal();
                    this.$nextTick(() => {
                        this.$refs.form.reset();
                    });
                })
                .catch(err => {
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });
            }
            });
        },
        eliminar(data) {
            Notify.Alert(
                "¿Estás seguro de eliminar este campo?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    apiContratos.deleteCampos(data.id).then(response => {
                        this.$refs.tabla.getData();
                        Notify.Success("Campo eliminado", "El campo ha sido eliminado satisfactoriamente");
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            )
        },

        /**
         * @method getClientes Este método realiza petición a la API de clientes.
         * @description Realiza petición a la API de clientes cuando el rol es root y el resultado lo almacena en el array clientes[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
        */
        getClientes(cliente = null) {
            let param = { activo: true, paginate: false };
            apiClientes.getClientes(param).then((response) => {
                this.clientes = response.data;
            })
            .catch((error) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },

        async loadModalData(){
            await this.resetValues();
            if (this.rol == "root") {
                this.getClientes();
            } else {
                this.cliente_value = this.datosLogin.cliente_id;
            } 
        },

        /**
         * @method setFilters Este método se ejecuta despues de dar clic en el botón buscar
         * @description Este método se ejecuta despues de dar clic en el botón buscar, se envian los filtros que se indicaron en el modal búsqueda. Y se reflejan en la tabla principal.
         * Se ejecuta como emit del componente tabla.
         */
        setFilters() {
            let cliente = this.cliente_value;

            if (this.rol !== "root") {
                cliente = this.datosLogin.cliente_id;
            } 

            let filterParams = {
                cliente_id: cliente,
            };

            let defaultParams = {
                activo    : true,
                paginate  : true,
                order     : "asc"
            };

            if (filterParams != null) Object.assign(defaultParams, filterParams);

            this.filters = defaultParams;
            this.cliente_value = null;
        },
        
    },
    created(){
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
    }
}
</script>

<style scoped>
    
</style>
